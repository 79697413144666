
export default {
  name: 'RevisitDialog',
  transition: 'myuse-fade',
  props: {
    showDialog: {
      type: Boolean,
      required: false,
    },
    user: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data: () => ({
    show_confirm_age: false,
    show_captcha: true,
    show_popup: true,
  }),
  computed: {
    display_notification() {
      return this.$store.getters['navigation/getDisplayNotificationStatus']
    },
  },

  openDialog() {
    this.show_popup = true
  },
  methods: {
    closeDialog() {
      this.show_popup = false
    },
    openDialog() {
      this.show_popup = true
    },
    async resume() {
      let login = null
      if (this.user) {
        login = this.user.password
      } else {
        this.$router.push({ path: '/page/' })
        return
      }

      await this.$store
        .dispatch('auth/login_user', {
          password: login,
        })
        .then(() => {
          // console.log('LOGGED IN - SETTNG GA', response)
        })
        .catch((response) => {
          this.$dialog.message.error('Error logging in.', {
            position: 'top',
            color: 'red',
          })
        })
    },
  },
}
